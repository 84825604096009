import { checkAndGenerateBaseUrl, Generator } from '.'

export const TrelloGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url, 'trello.com')
    if (!url) {
      return null
    }
    const parts = url.pathname.split('/')

    if (parts.length < 3 || !['b', 'c'].includes(parts[1])) {
      return null
    }
    /*
    if (url.pathname.includes('/b/')) {}
    if (url.pathname.includes('/c/')) {}
    */

    return new URL(`https://trello.com/${parts[1]}/${parts[2]}.html`)
  }

  return {
    generateUrl
  }
}
