/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { ReactElement, useEffect, useState } from 'react'
import './App.css'
import 'monday-ui-react-core/dist/main.css'
import { useEffectOnce, useLocation, useSearchParam } from 'react-use'
import DOMPurify from 'dompurify'
import { getMondaySdk, handleItemViewSettings, isUserViewer } from './common/helpers/monday'
import { ViewerWarning } from './common/components/viewer-warning'
import { Loading } from './common/components/loading'
import { MonetizableEmbedder } from './embedder/monetizable-embedder'
import { sendEvent } from './events/event-manager'
import { appLog } from './common/helpers/system'
import { MondayContext } from './common/types/monday'

const monday = getMondaySdk()

export const App = (): ReactElement => {
  const [settings, setSettings] = useState<any>()
  const [context, setContext] = useState<MondayContext>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const searchParam = DOMPurify.sanitize(useSearchParam('app'))
  const pathParam = DOMPurify.sanitize(useLocation().pathname?.split('/')[1])
  const app = searchParam || pathParam

  useEffectOnce(() => {
    void sendEvent({
      eventId: 'open',
      eventName: 'Open App'
    })
  })

  useEffect(() => {
    try {
      monday.listen('settings', (res) => {
        void start(res)
      })
    } catch (e) {
      setIsLoading(false)
      appLog(e)
    }
  }, [])

  const start = async (res): Promise<void> => {
    const newSettings = res.data
    const contextRes = await monday.get('context')
    const newContext: MondayContext = contextRes.data

    // console.log('settings', newSettings)
    // console.log('context', contextRes)

    await handleItemViewSettings(newSettings, newContext)
    // console.log('handleItemViewSettings', newSettings)

    setSettings(newSettings)
    setContext(newContext)
    setIsLoading(false)
  }

  /*
  console.log('settings', settings)
  console.log('context', context)
  */

  const HandleLoading = (): JSX.Element => {
    return (
      <div className='App align-center'>
        <Loading />
      </div>
    )
  }

  if (isLoading) {
    return <HandleLoading />
  }

  if (isUserViewer(context)) {
    return (
      <div className='App align-center'>
        <ViewerWarning />
      </div>
    )
  }

  return (
    <div className='App'>
      <MonetizableEmbedder
        app={app}
        settings={settings}
        context={context}
        onLoading={<HandleLoading />}
      />
    </div>
  )
}

export default App
