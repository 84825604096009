/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
// NOTE: The nocheck is needed because the types for AlertBanner are wrongly written by Monday.com
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { AttentionBox, Link, Icon } from 'monday-ui-react-core'
import { Configs } from './config'
import { Deactivate, Settings, ExternalPage } from 'monday-ui-react-core/icons'

export const Onboarding = ({ app, isItemView, isUrlEmpty }: {app: string, isItemView?: boolean, isUrlEmpty: boolean}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

  let onboardingUrl = `/onboarding/${app}.svg`
  if (isItemView) {
    onboardingUrl = `/onboarding/${app}-item.svg`
  }
  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ alignItems: 'center', margin: '15px 0px' }}>
        {
          isUrlEmpty
            ? <AttentionBox title={'Let\'s embed!'} type={AttentionBox.types.PRIMARY} icon={Settings}>
                Open the app settings <Icon icon={Settings} iconLabel='Settings' clickable={false} iconSize={14} /> and enter an embeddable URL link.<br />
                Need help? 👉 <Link inlineText text='User Guide' href={Configs[app].docLink} iconPosition={Link.iconPositions.END} icon={ExternalPage} />
              </AttentionBox>
            : <AttentionBox title='That link is not embeddable!' type={AttentionBox.types.DANGER} icon={Deactivate}>
                Open the app settings <Icon icon={Settings} iconLabel='Settings' clickable={false} iconSize={14} /> and enter an embeddable URL link.<br />
                Need help? 👉 <Link inlineText text='User Guide' href={Configs[app].docLink} iconPosition={Link.iconPositions.END} icon={ExternalPage} />
              </AttentionBox>
        }
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'calc(100vh - 135px)', overflowY: 'auto' }}>
        <img src={onboardingUrl} style={{ maxWidth: 600, marginLeft: '10%', marginRight: '10%', marginBottom: '30px' }} alt='onboarding' />
      </div>
    </div>
  )
}
