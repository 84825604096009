/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { ReactElement, useEffect, useState } from 'react'
import 'monday-ui-react-core/dist/main.css'

import { getAccountInfo } from '../common/helpers/monday'
import { appLog } from '../common/helpers/system'
import { MondayAccount } from '../common/types/monday'
import { Configs, PaywallLevel } from './config'
import { WarningModal } from './warning-modal'
import { Embedder } from './embedder'
import { ForceUpgradeModal } from './force-upgrade-modal'

const allowedAccounts = [
  '12298619', // Presago
  '18678142' // Andrea test
]

const planIdMaxUsers = {
  msstream_5: 5,
  msstream_10: 10,
  msstream_20: 20,
  msstream_50: 20,
  msstream_100: 100,
  msstream_200: 200,
  msstream_500: 500,
  msstream_1000: 1000,
  msstream_2500: 2500
}

const exceptionPlanId = [
  'msstream_unlimited',
  'test_plan'
]

const FREE_TIER_MAX_USERS = 5

const getSubscriptionPlanMaxUsers = (planId: string): number => {
  const parsed = parseInt(planId?.split('_')?.[1] ?? '0')
  appLog('parsed plan maxUsers', parsed)
  return parsed
}

const isExceptionPlanId = (planId: string): boolean => {
  return planId.includes('unlimited') || planId === 'test_plan'
}

interface EmbedderProps {
  app: string
  settings: any
  context: any
  onLoading: JSX.Element
}

export const MonetizableEmbedder = ({ app, settings, context, onLoading }: EmbedderProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentPaywall, setCurrentPaywall] = useState<PaywallLevel>(PaywallLevel.NONE)
  const [accountPlan, setAccountPlan] = useState<any>()
  const [forceUpgrade, setForceUpgrade] = useState<boolean>(false)

  useEffect(() => {
    void paywallCheck()
  }, [context])

  const paywallCheck = async (): Promise<void> => {
    if (context) {
      // if (context && Configs[app].paywall === PaywallLevel.WARNING && allowedAccounts.includes(context.account.id)) {
      appLog('WarningModal context', context)

      // if isTrial OR correctSub THEN none ELSE block
      let paywallToSet = PaywallLevel.BLOCK

      const accountInfo = await getAccountInfo().catch(() => {
        // console.warn('getAccountPlan error', error)
        setForceUpgrade(true)
        return undefined
      })

      appLog(`${Configs[app].label}: (sub - accountInfo) =`, context?.subscription?.plan_id, accountInfo)

      // Main algorithm
      if (context?.subscription) {
        if (context.subscription.days_left > 0 && (context.subscription.is_trial || await hasCorrectSubTier(context, accountInfo, false))) {
          // Paywall none case
          paywallToSet = PaywallLevel.NONE
        }
      } else {
        // Old customers
        if (accountInfo && accountInfo.active_members_count <= FREE_TIER_MAX_USERS) {
          // NOTE: Hack to fix a Monday unmanaged case (if plan is undefined, the case remains BLOCK by default)
          appLog('Monday.com unmanaged case')
          paywallToSet = PaywallLevel.NONE
        } else {
          if (Date.now() <= Configs[app].transitionDate.getTime()) {
            // Paywall warning case
            paywallToSet = PaywallLevel.WARNING
          }
        }
      }

      // Results
      if (Configs[app].paywall === PaywallLevel.WARNING) {
        appLog(`${Configs[app].label}: paywall =`, PaywallLevel[paywallToSet])
        setAccountPlan(accountInfo?.plan)
        setCurrentPaywall(paywallToSet)
      } else {
        appLog(`${Configs[app].label}: simulated_paywall =`, PaywallLevel[paywallToSet])
      }
    }
    setIsLoading(false)
  }

  const hasCorrectSubTier = async (context: any, accountInfo: MondayAccount | undefined, defaultOutput: boolean): Promise<boolean> => {
    let res: boolean = defaultOutput

    if (context?.subscription) {
      if (isExceptionPlanId(context.subscription.plan_id)) {
        res = true
      } else {
        if (accountInfo?.plan) {
          // TODO: New variable active_members_count
          res = (Number.isInteger(accountInfo.active_members_count) && accountInfo.active_members_count <= getSubscriptionPlanMaxUsers(context.subscription.plan_id))
        } else {
          if (accountInfo?.plan === null) {
            // Account with an Monday.com trial
            res = true
          }
        }
      }
    } else {
      res = false
    }

    return res
  }

  if (isLoading) {
    return onLoading
  }

  return (
    <div style={{ flex: 1 }}>
      {
        // TODO: The forcedUpgrade modal must be seen if we dont have plan permission
        forceUpgrade && currentPaywall === PaywallLevel.BLOCK
          ? <ForceUpgradeModal />
          : <WarningModal app={app} context={context} paywall={currentPaywall} accountPlan={accountPlan} />
      }
      {
        currentPaywall !== PaywallLevel.BLOCK &&
          <Embedder
            app={app}
            settings={settings}
            context={context}
            onLoading={onLoading}
          />
      }
    </div>
  )
}
