import { Loader } from 'monday-ui-react-core'
import React, { ReactElement } from 'react'

export const Loading = (): ReactElement => {
  return (
    <div>
      <Loader size={40} />
    </div>
  )
}
