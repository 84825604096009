import { checkAndGenerateBaseUrl, Generator } from '.'

export const PowerappsGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url, 'apps.powerapps.com/play/') ?? checkAndGenerateBaseUrl(settings.url, 'apps.powerapps.com/play/')
    if (!url) {
      return null
    }

    url.searchParams.set('source', 'iframe')
    return url
  }

  return {
    generateUrl
  }
}
