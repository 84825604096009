import { checkAndGenerateBaseUrl, Generator } from '.'

export const GenericGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url)
    if (!url) {
      return null
    }

    return url
  }

  return {
    generateUrl
  }
}
