import { AttentionBox } from 'monday-ui-react-core'
import React, { ReactElement } from 'react'

export const ViewerWarning = (): ReactElement => {
  return (
    <div className='App'>
      <AttentionBox
        title='You are a viewer'
        text='Viewers cannot use this app'
        type={AttentionBox.types.DANGER}
      />
    </div>
  )
}
