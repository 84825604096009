import { getMondaySdk } from '../common/helpers/monday'
import { Configs } from '../embedder/config'

export interface ClientEvent {
  eventId: string
  eventName: string
  [key: string]: any
}

export const sendEvent = async (event: ClientEvent): Promise<void> => {
  const url = new URL(window.location.href)
  let appSlug = url.pathname.split('/').pop()
  if (!appSlug) {
    appSlug = url.searchParams.get('app') ?? undefined
  }
  if (!appSlug || !Configs[appSlug].enableEvents) return

  const mondaySdk = getMondaySdk()
  const sessionToken = await mondaySdk.get('sessionToken')
  const context = await mondaySdk.get('context')
  const payload = {
    event: event,
    context: { ...context.data, user: { countryCode: context.data.user.countryCode, currentLanguage: context.data.user.currentLanguage } }
  }
  await fetch('/event', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: sessionToken.data
    },
    body: JSON.stringify(payload)
  })
}
