/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useRef, useState } from 'react'
import 'monday-ui-react-core/dist/main.css'
import { Button, Modal, ModalContent, ModalFooter, Flex } from 'monday-ui-react-core'
import { openPayment } from '../common/helpers/monday'
import { Configs, PaywallLevel } from './config'

export const WarningModal = ({ app, context, paywall, accountPlan }: {app: string, context: any, paywall: PaywallLevel, accountPlan: any}): JSX.Element => {
  const [show, setShow] = useState(true)
  const openModalButtonRef = useRef()
  const closeModal = useCallback(() => {
    setShow(false)
  }, [])

  if (paywall !== PaywallLevel.NONE) {
    const warningCase = {
      title: 'Pricing changes',
      content: (
        <div>
          <p>
            Starting from {Configs[app].transitionDate.toISOString().split('T')[0]}, free versions for non-startup teams will be discontinued. This app will shift to seat-based pricing, with a free 5-user startup plan. Be sure to select a plan before that time to avoid interruptions in your workflow.
          </p>
          <p>
            Visit <a href='https://presago.atlassian.net/servicedesk/customer/portals' target='_blank' rel='noreferrer'>our portal</a> to learn more about the transition and contact us for any needs.
          </p>
        </div>
      ),
      mainButtonLabel: 'Pricing',
      hideCloseBtn: false
    }

    const blockingCase = {
      title: 'Upgrade your plan',
      content: (
        <div>
          {
            context.subscription?.is_trial &&
              <p>
                Your free trial has ended.
              </p>
          }
          <p>
            Please subscribe or renew your subscription to a plan that fits the size of your team.
          </p>
        </div>
      ),
      mainButtonLabel: 'Upgrade',
      hideCloseBtn: true
    }

    const modalSettings = paywall === PaywallLevel.WARNING ? warningCase : blockingCase

    return (
      <Modal
        data-testid=''
        id='warning-modal'
        onClose={modalSettings.hideCloseBtn ? () => null : closeModal}
        title={modalSettings.title}
        triggerElement={openModalButtonRef.current}
        show={show}
        contentSpacing={false}
        alertDialog
        hideCloseButton
      >
        <ModalContent>
          {modalSettings.content}
        </ModalContent>
        <ModalFooter>
          <Flex
            gap={12}
            justify={Flex.justify.END}
          >
            {!modalSettings.hideCloseBtn &&
              <Button kind={Button.kinds.TERTIARY} onClick={closeModal}>
                Close
              </Button>}
            <Button onClick={openPayment}>
              {modalSettings.mainButtonLabel}
            </Button>
          </Flex>
        </ModalFooter>
      </Modal>
    )
  }

  return (<></>)
}
