import { checkAndGenerateBaseUrl, Generator } from '.'

export const MsstreamGenerator = (): Generator => {
  const hmsToSecondsOnly = (str: string): number => {
    const parts = str.split(':')
    let s = 0; let m = 1

    while (parts.length > 0) {
      const part = parts.pop()
      if (part) {
        s += m * parseInt(part, 10)
        m *= 60
      }
    }

    return s
  }

  const generateUrl = (settings: any): URL | null => {
    let urlFromSettings = settings.url

    // Try to parse iframe src
    const iframe = new DOMParser().parseFromString(settings.url, 'text/html').getElementsByTagName('iframe')
    if (iframe.length > 0) {
      urlFromSettings = iframe[0].src
    }

    // Check url
    let url = checkAndGenerateBaseUrl(urlFromSettings)
    if (!url) {
      return null
    }

    // Checks on the 2 url formats
    const isType1Url = url.hostname.includes('microsoftstream.com') && url.pathname.includes('video')
    const isType2Url = url.hostname.includes('sharepoint.com') && (url.pathname.includes('stream.aspx') || url.pathname.includes('embed.aspx'))
    if (!isType1Url && !isType2Url) { return null }

    if (isType1Url) {
      // Correctly formatting
      if (url.toString().includes('https://web.microsoftstream.com/video/')) {
        const segments = url.pathname.split('/')
        let last: string | undefined
        do {
          last = segments.pop()
        } while (!last)
        url = new URL(`https://web.microsoftstream.com/embed/video/${last}`)
      }

      // Adding options
      if (typeof settings.autoplay !== 'undefined') {
        url.searchParams.set('autoplay', settings.autoplay)
      }
      url.searchParams.set('showinfo', settings.showinfo)

      if (settings.start) {
        // Convert to seconds
        let st = 0
        st = hmsToSecondsOnly(settings.start)
        url.searchParams.set('st', st.toString())
      }
    }

    if (isType2Url) {
      // Correctly formatting
      if (url.pathname.includes('stream.aspx')) {
        url.pathname = url.pathname.replace('stream.aspx', 'embed.aspx')
      }

      // TODO: Check params for the new format
      // Adding options
      if (typeof settings.autoplay !== 'undefined') {
        const json = JSON.parse(url.searchParams.get('embed') ?? '{"hvm":true,"ust":true}')
        json.af = settings.autoplay
        url.searchParams.set('embed', JSON.stringify(json))
      }
      if (settings.start) {
        // Convert to seconds
        let st = 0
        st = hmsToSecondsOnly(settings.start)
        const json = JSON.parse(url.searchParams.get('nav') ?? '{}')
        json.playbackOptions = { startTimeInSeconds: st }
        url.searchParams.set('nav', JSON.stringify(json))
      }
    }

    return url
  }

  return {
    generateUrl
  }
}
