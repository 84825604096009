import React, { ReactElement, useEffect, useState } from 'react'
import { AttentionBox } from 'monday-ui-react-core'
import { getMondaySdk } from '../common/helpers/monday'
import { getUrlGenerator } from './generators'
import { Onboarding } from './onboarding'

interface EmbedderProps {
  app?: string | null
  settings: any
  context: any
  onLoading: JSX.Element
}

const monday = getMondaySdk()

export const Embedder = ({ app, settings, context, onLoading }: EmbedderProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [url, setUrl] = useState<any>()
  const [frameWidth, setFrameWidth] = useState('100%')
  const [frameHeight, setFrameHeight] = useState('100%')

  useEffect(() => {
    // console.log(app, settings)

    if (app && settings) {
      setFrameWidth(settings.width && settings.width.length > 0 ? settings.width : '100%')
      setFrameHeight(settings.height && settings.height.length > 0 ? settings.height : '100%')
      const generator = getUrlGenerator(app)
      const checkedUrl = generator?.generateUrl(settings)
      setUrl(checkedUrl)
      if (checkedUrl) {
        void monday.execute('valueCreatedForUser')
      }
    }
    setIsLoading(false)
  }, [app, settings])

  if (isLoading) {
    return onLoading
  }

  if (!app) {
    return (
      <AttentionBox
        title='Invalid request'
        text='Missing app parameter'
        type={AttentionBox.types.DANGER}
      />
    )
  }

  if (!settings) {
    return (
      <AttentionBox
        title='Invalid configuration'
        type={AttentionBox.types.DANGER}
      />
    )
  }

  const isUrlEmpty = (typeof settings.url === 'undefined' || settings.url?.length === 0)
  // console.log('URL', url?.toString())
  if (!url) {
    return (
      <Onboarding app={app} isItemView={context.instanceType === 'item_view'} isUrlEmpty={isUrlEmpty} />
    )
  }

  return (
    <div style={{ width: frameWidth, height: frameHeight, overflow: 'hidden' }}>
      <iframe
        sandbox='allow-downloads allow-forms allow-modals allow-popups allow-scripts allow-same-origin allow-top-navigation-by-user-activation'
        allow={app === 'powerapps' ? 'geolocation; microphone; camera; fullscreen;' : 'fullscreen;'}
        title='Presago Embedded App'
        style={{ border: 0, width: '100%', height: '100%' }}
        src={url.toString()}
      />
      {app === 'trello' ? <script src={generateScriptUrl(url)} /> : null}
    </div>
  )
}

function generateScriptUrl (url: URL): string | undefined {
  const parts = url.pathname.split('/')

  if (parts.length < 3 || !['b', 'c'].includes(parts[1])) {
    return ''
  }

  return `https://trello.com/${parts[1]}/${parts[2]}.js`
}
