import { booleanify, checkAndGenerateBaseUrl, Generator } from './'

export const PowerbiGenerator = (): Generator => {
  const getId = (replace: string, url: URL): string | undefined => {
    // eslint-disable-next-line no-useless-escape
    const base = '\/([a-zA-z0-9-]*)'
    const regex = new RegExp(replace + base, 'g')
    let id = url.pathname.match(regex)?.[0]
    id = id?.replace(replace + '/', '')
    return id
  }

  const generateUrl = (settings: any): URL | null => {
    settings.filterPaneEnabled = booleanify(settings.filterPaneEnabled)
    settings.navContentPaneEnabled = booleanify(settings.navContentPaneEnabled)

    const url = checkAndGenerateBaseUrl(settings.url, 'powerbi.com')
    if (!url) {
      return null
    }

    if (url.toString().includes('/reports')) {
      const id = getId('reports', url)
      if (!id) {
        return null
      }
      const embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${id}&autoAuth=true`
      return new URL(embedUrl)
    }

    if (url.toString().includes('/scorecards')) {
      const id = getId('scorecards', url)
      if (!id) {
        return null
      }
      const embedUrl = `https://app.powerbi.com/scorecardEmbed?scorecardId=${id}&autoAuth=true`
      return new URL(embedUrl)
    }

    if (settings.filter) {
      url.searchParams.set('filter', encodeURI(settings.filter))
    }

    if (settings.pageName) {
      url.searchParams.set('pageName', settings.pageName)
    }

    url.searchParams.set('filterPaneEnabled', settings.filterPaneEnabled)
    url.searchParams.set('navContentPaneEnabled', settings.navContentPaneEnabled)

    return url
  }

  return {
    generateUrl
  }
}
