import { checkAndGenerateBaseUrl, Generator } from '.'

export const GdocsGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url)
    if (!url) {
      return null
    }

    if (!url.pathname.includes('document')) { return null }

    return url
  }

  return {
    generateUrl
  }
}
