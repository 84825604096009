import { Generator } from './generators/.'
import { GdocsGenerator } from './generators/gdocs-generator'
import { GenericGenerator } from './generators/generic-generator'
import { GformsGenerator } from './generators/gforms-generator'
import { GsheetsGenerator } from './generators/gsheets-generator'
import { GslidesGenerator } from './generators/gslides-generator'
import { MsformsGenerator } from './generators/msforms-generator'
import { MsstreamGenerator } from './generators/msstream-generator'
import { PowerappsGenerator } from './generators/powerapps-generator'
import { PowerbiGenerator } from './generators/powerbi-generator'
import { TrelloGenerator } from './generators/trello-generator'
import { VisioGenerator } from './generators/visio-generator'

export enum PaywallLevel { NONE, WARNING, BLOCK }

export interface EmbedderConfig {
  label: string
  docLink: string
  generator: Generator
  paywall: PaywallLevel
  transitionDate: Date
  enableEvents?: boolean
}

export const Configs: { [key: string]: EmbedderConfig } = {
  generic: {
    label: 'Generic Embedder',
    docLink: 'http://bit.ly/3WAuBol',
    generator: GenericGenerator(),
    paywall: PaywallLevel.NONE,
    transitionDate: new Date('2023-12-25T00:00:00Z')
  },
  powerbi: {
    label: 'Microsoft PowerBi',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013612545',
    generator: PowerbiGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2024-04-15T00:00:00Z'),
    enableEvents: false
  },
  msforms: {
    label: 'Microsoft Forms',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013645564',
    generator: MsformsGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2023-12-15T00:00:00Z'),
    enableEvents: false
  },
  gforms: {
    label: 'Google Forms',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013580042',
    generator: GformsGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2024-04-08T00:00:00Z'),
    enableEvents: false
  },
  msstream: {
    label: 'Microsoft Stream',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013645313',
    generator: MsstreamGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2023-10-16T00:00:00Z'),
    enableEvents: false
  },
  trello: {
    label: 'Trello',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013646066',
    generator: TrelloGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2023-12-15T00:00:00Z'),
    enableEvents: false
  },
  visio: {
    label: 'Microsoft Visio',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013612796',
    generator: VisioGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2023-12-15T00:00:00Z'),
    enableEvents: false
  },
  gdocs: {
    label: 'Google Docs',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013579777',
    generator: GdocsGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2024-06-24T00:00:00Z'),
    enableEvents: false
  },
  gsheets: {
    label: 'Google Sheets',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013579777',
    generator: GsheetsGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2024-06-24T00:00:00Z')
  },
  gslides: {
    label: 'Google Slides',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013579777',
    generator: GslidesGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2024-04-08T00:00:00Z'),
    enableEvents: false
  },
  powerapps: {
    label: 'Microsoft PowerApps',
    docLink: 'https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1013645815',
    generator: PowerappsGenerator(),
    paywall: PaywallLevel.WARNING,
    transitionDate: new Date('2023-12-15T00:00:00Z'),
    enableEvents: false
  }
}
