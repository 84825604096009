import { checkAndGenerateBaseUrl, Generator } from '.'

export const MsformsGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url)
    if (!url) {
      return null
    }

    if (!(url.hostname.includes('forms.office.com') || url.hostname.includes('forms.microsoft.com'))) { return null }

    return url
  }

  return {
    generateUrl
  }
}
