/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useRef, useState } from 'react'
import 'monday-ui-react-core/dist/main.css'
import { Button, Modal, ModalContent, ModalFooter, Flex } from 'monday-ui-react-core'
import { openLink } from '../common/helpers/monday'

export const ForceUpgradeModal = (): JSX.Element => {
  const [show, setShow] = useState(true)
  const openModalButtonRef = useRef()
  const closeModal = useCallback(() => {
    setShow(false)
  }, [])

  const upgradeCase = {
    title: 'App update required',
    content: (
      <div>
        <p>
          Following the announced switch to <b>seat-based plans</b>, this version of the app is now <b>discontinued </b>and won’t receive the latest security and software integrity updates.
        </p>
        <p>
          To ensure the continuity of your team's operations, <b>update the app</b> and <b>select a plan</b> that fits the size of your team (free for up to 5 users).
        </p>
      </div>
    ),
    hideCloseBtn: true
  }

  return (
    <Modal
      data-testid=''
      id='force-upgrade-modal'
      title={upgradeCase.title}
      triggerElement={openModalButtonRef.current}
      show={show}
      contentSpacing={false}
      alertDialog
      hideCloseButton
      onClose={() => null}
    >
      <ModalContent>
        {upgradeCase.content}
      </ModalContent>
      <ModalFooter>
        <Flex
          gap={12}
          justify={Flex.justify.END}
        >
          <Button onClick={() => openLink('https://presago.atlassian.net/wiki/spaces/PRESDOC/pages/1025409027')}>
            Continue
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  )
}
