import { checkAndGenerateBaseUrl, Generator } from '.'

export const GformsGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url)
    if (!url) {
      return null
    }

    if (!(url.hostname.includes('docs.google.com') && url.pathname.includes('forms')) && !url.hostname.includes('forms.gle')) { return null }

    url.pathname = url.pathname.replace(/\/edit$/, '/viewform')
    return url
  }

  return {
    generateUrl
  }
}
