import { checkAndGenerateBaseUrl, Generator } from '.'

export const VisioGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url)
    if (!url) {
      return null
    }

    // Checks on the 2 url formats
    const isType1Url = url.hostname.includes('sharepoint.com') && url.searchParams.has('sourcedoc')
    const isType2Url = (url.hostname.includes('onedrive.live.com') && url.pathname.includes('embed'))
    if (!isType1Url && !isType2Url) { return null }

    // Adjust type1 format (Sharepoint standard browser link)
    if (isType1Url) {
      url.searchParams.set('action', 'embedview')
    }
    // if (result.includes('appName = \'Visio\'')) {}

    return url
  }

  return {
    generateUrl
  }
}
