import { checkAndGenerateBaseUrl, Generator } from '.'

export const GsheetsGenerator = (): Generator => {
  const generateUrl = (settings: any): URL | null => {
    const url = checkAndGenerateBaseUrl(settings.url, 'google.com')
    if (!url) {
      return null
    }

    if (!url.pathname.includes('spreadsheets')) { return null }

    return url
  }

  return {
    generateUrl
  }
}
