/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import mondaySdk from 'monday-sdk-js'
import { MondayClientSdk } from 'monday-sdk-js/types/client-sdk.interface'
import { MondayAccount, MondayContext, MondayPlan } from '../types/monday'

let monday: MondayClientSdk

export const getMondaySdk = (): MondayClientSdk => {
  if (!monday) {
    // console.log('A new monday')
    monday = mondaySdk()
    monday.setApiVersion('2024-07')
  }
  return monday
}

const columnValueToSetting = (columnValue): any => {
  if (columnValue === null || typeof columnValue === 'string') { return columnValue } // Support for column type text
  if (typeof columnValue.url !== 'undefined') { return columnValue.url } // Support for column type link
  if (typeof columnValue.checked !== 'undefined') { return JSON.parse(columnValue.checked) } // Support for column type checkbox
  return undefined
}

export const handleItemViewSettings = async (newSettings, newContext): Promise<void> => {
  // Preliminary checks: all needed data loaded, user is browsing an item view, user isn't viewonly
  if (typeof newContext !== 'undefined' && typeof newSettings !== 'undefined' && newContext.instanceType === 'item_view' && !newContext.user.isViewOnly) {
    const promises: Array<Promise<void>> = []
    // Iterate all settings' fields
    for (const field in newSettings) {
      // Only if field is a dictionary...
      if (typeof newSettings[field] === 'object' && newSettings[field] !== null && !Array.isArray(newSettings[field])) {
        const [columnId, checkType] = Object.entries(newSettings[field])[0] ?? [undefined, undefined]

        // ...that contains a boolean
        if (typeof checkType === 'boolean') {
          // console.log('ItemView', field, columnId)
          newSettings[field] = '' // Default empty value

          const query = `query { items(ids:${newContext.itemId as string}) {column_values(ids:"${columnId}") { value, text } } }`

          // Async query data and assign them
          const promise = getMondaySdk().api(query).then(res => {
            const ret = JSON.parse((res.data as any).items[0].column_values?.[0].value)
            const setting: any = columnValueToSetting(ret)
            /*
            const logData = {
              field: field,
              columnId: columnId,
              columnValue: ret,
              setting: setting
            }
            console.log('ColumnValue', logData)
            */
            newSettings[field] = setting
          })

          promises.push(promise)
        }
      }
    }

    // Awaiting every request (if one fails, the detault value is already assigned)
    await Promise.allSettled(promises)
  }
}

export const isUserViewer = (context: MondayContext | any | undefined): boolean => {
  return typeof context?.user?.isViewOnly !== 'undefined' && context.user.isViewOnly
}

export const openPayment = (): void => {
  void getMondaySdk().execute('openPlanSelection', { isInPlanSelection: true })
}

export const openLink = (url: string): void => {
  void getMondaySdk().execute('openLinkInTab', { url: url })
}

export const getAccountPlan = async (): Promise<MondayPlan | undefined> => {
  const query = 'query { account { plan { max_users period tier version } } }'

  // Async query data and assign them
  const promise = getMondaySdk().api(query).then(res => {
    if (!res?.data) {
      console.warn('getAccountPlan monday API error', res)
      return undefined
    }

    return (res?.data as any).account.plan as MondayPlan
  })
  /*
  .catch((error) => {
    console.warn('getAccountPlan error', error)
    return undefined
  })
  */

  return await promise
}

export const getAccountInfo = async (): Promise<MondayAccount | undefined> => {
  const query = `query { 
                  account { 
                    id
                    name
                    slug
                    country_code
                    tier
                    plan { max_users period tier version }
                    active_members_count
                  } 
                }`

  // Async query data and assign them
  const promise = getMondaySdk().api(query).then(res => {
    if (!res?.data?.account) {
      console.warn('getAccountInfo monday API error', res)
      return undefined
    }
    return (res?.data as any).account as MondayAccount
  })
  /*
  .catch((error) => {
    console.warn('getAccountPlan error', error)
    return undefined
  })
  */

  return await promise
}
