import { Configs } from '../config'

export interface Generator {
  generateUrl: (settings: any) => URL | null
}

export const checkAndGenerateBaseUrl = (stringUrl: string, pathToVerify?: string): URL | null => {
  let url: URL
  try {
    url = new URL(stringUrl)
    if (url.protocol !== 'http:' && url.protocol !== 'https:') {
      return null
    }
    if (typeof pathToVerify !== 'undefined') {
      if (!url.toString().includes(pathToVerify)) {
        return null
      }
    }

    return url
  } catch (e) {
    // console.log(e)
    return null
  }
}

export const booleanify = (value: any): boolean => {
  return value === true
}

export const getUrlGenerator = (app: string): Generator | null => {
  if (Object.keys(Configs).includes(app)) {
    return Configs[app].generator
  } else {
    return null
  }
}
